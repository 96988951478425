<div *ngIf="(cart$|async) as cart;" class="d-flex flex-column cart-root h-100">
  <div class="d-flex flex-row align-items-center justify-content-between" style="width: 99%">
    <h2 class="cart-header mat-headline-5">Your Order</h2>
    <button mat-icon-button (click)="sidebarSvc.toggle()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  @if (cart.items.length===0){
    <div class="cart-body d-flex align-items-center justify-content-center flex-column">

      <div class="d-flex justify-content-center align-items-center text-muted"
           style="background-color: rgba(200,200,200,0.2); width: 90px; height: 90px; border-radius: 999em;">
        <fa-icon [icon]="falCart" size="3x"></fa-icon>
      </div>

      <div class="text-center mt-4">
        <p>Ready to go!<br>
          Add items to get started</p>
      </div>
    </div>
    <div class="footer-powered text-center py-2">
      powered by <a
      href="https://orders.swiftserve.io/local"><img alt="Discover SwiftServe Powered Restaurants" class="ml-4"
                                                     src="https://assets.swiftserve.io/logo4.png"></a>
    </div>
  } @else {
    <div class="d-flex align-items-start justify-content-start flex-grow-1 ps-3">
      <table class="cart-table">
        <tbody>
        <tr *ngFor="let item of cart.items; index as i;" role="group">
          <td class="cart-item-q">{{item.quantity}}</td>
          <td class="cart-item-n">
            <span class="item-name">{{item.name}} ({{item.price.price|currency}})</span>
            <ul *ngIf="item.modifiers.length > 0" class="cart-modifiers">
              <li *ngFor="let mod of item.modifiers" class="cart-modifier text-muted">
                <span *ngIf="mod.quantity>1" class="modifier-quantity"> {{mod.quantity}}</span>
                {{mod.name}} <ng-container *ngIf="mod.getTotal()>0">(+{{mod.getTotal()|currency}})</ng-container>
              </li>
            </ul>
            <div *ngIf="item.specialInstructions!=null && item.specialInstructions.length>0"
                 class="text-muted">{{item.specialInstructions}}</div>
            <button (click)="edit(item)" mat-stroked-button class="me-3">Edit</button>
            <button (click)="remove(item)" class="warn-button" mat-button>Remove</button>
          </td>
          <td class="cart-item-p">
            @if (item.discountAmount>0){
              <span class="text-strike">{{item.getTotal()|currency}}</span>
              {{item.getDiscountedTotal()|currency}}
            } @else {
              {{item.getTotal()|currency}}
            }

          </td>
        </tr>
        </tbody>
      </table>
      <div class="flex-grow-1"></div>
    </div>
    <div class="cart-footer">
      <button class="button-checkout-2"  [routerLink]="['.', 'checkout']" mat-flat-button>
        <div  class="d-flex justify-content-between align-items-center flex-grow-1">
          <span>Continue to checkout ({{getQuantityTotal(cart)}})</span>
          <span>Subtotal: {{getOrderTotal(cart)|currency}}</span>
        </div>
      </button>
    </div>
  }



</div>



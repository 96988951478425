import {Routes} from '@angular/router';
import {shopResolverFn} from "./resolvers/shop.resolver";
import {MainLayoutComponent} from "./pages/main-layout/main-layout.component";

export const ROUTES: Routes = [{
  path: 'w/:site',
  component: MainLayoutComponent,
  resolve: {
    website: shopResolverFn
  },
  children: [
    {
      path: 'selector',
      loadComponent: () => import('./pages/location-selector/location-selector.component').then(m => m.LocationSelectorComponent),
      data: {animation: 'selectorPage'}
    },
    {
      path: 'l/:token',
      loadComponent: ()=> import('./components/main-shop/main-shop.component').then(m => m.MainShopComponent),
      data: {
        isShop: true,
        animation: 'shopPage'
      }
    }, {
      path: 'checkout',
      loadComponent: () => import('./pages/checkout/checkout.component').then(m => m.CheckoutComponent),
      data: {animation: 'checkoutPage'}
    }, {
      path: 'track-order/:token',
      loadComponent: () => import('./pages/tracking/tracking.component').then(m => m.TrackingComponent),
      data: {animation: 'trackPage'}
    }, {
      path: '**',
      redirectTo: 'selector'
    }
  ]
},
  {
    path: '',
    component: MainLayoutComponent,
    resolve: {
      website: shopResolverFn
    },
    children: [
      {
        path: 'selector',
        loadComponent: () => import('./pages/location-selector/location-selector.component').then(m => m.LocationSelectorComponent),
        data: {animation: 'selectorPage'}
      },
      {
        path: 'l/:token',
        loadComponent: ()=> import('./components/main-shop/main-shop.component').then(m => m.MainShopComponent),
        data: {
          isShop: true,
          animation: 'shopPage'
        }
      }, {
        path: 'checkout',
        loadComponent: () => import('./pages/checkout/checkout.component').then(m => m.CheckoutComponent),
        data: {animation: 'checkoutPage'}
      }, {
        path: 'track-order/:token',
        loadComponent: () => import('./pages/tracking/tracking.component').then(m => m.TrackingComponent),
        data: {animation: 'trackPage'}
      }, {
        path: '**',
        redirectTo: 'selector'
      }
    ]
  }
];

import {HttpEvent, HttpHandler, HttpHandlerFn, HttpHeaders, HttpInterceptor, HttpInterceptorFn, HttpRequest, HttpResponse} from "@angular/common/http";
import {inject, Injectable} from "@angular/core";
import {catchError, Observable} from "rxjs";
import {WebsiteService} from "../services/website.service";
import {isStringNotEmpty} from "../utils/Utils";
import {LoadingService} from "../services/loading.service";
import {map} from "rxjs/operators";

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  constructor(private service: WebsiteService, private loadSvc: LoadingService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.handleAccess(req, next);
  }

  private handleAccess(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.service.websiteIdentifier;
    this.loadSvc.setLoading(true, request.url);

    const headerSettings: { [name: string]: string | string[]; } = {};
    for (const key of request.headers.keys()) {
      // @ts-ignore
      headerSettings[key] = request.headers.getAll(key);
    }
    if (isStringNotEmpty(token)) {
      headerSettings['R-Shop'] = token;
    }
    headerSettings['Content-Type'] = 'application/json';
    const newHeader = new HttpHeaders(headerSettings);
    const changedReq = request.clone({headers: newHeader});
    return next.handle(changedReq).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.loadSvc.setLoading(false, request.url);
        }
        return event;
      }),
      catchError((error) => {
        this.loadSvc.setLoading(false, request.url);
        throw error;
      })
    );
  }
}

export const AuthHttpInterceptorFn: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const service = inject(WebsiteService);
  const loadSvc = inject(LoadingService);

  const token = service.websiteIdentifier;
  loadSvc.setLoading(true, req.url);

  let headers = isStringNotEmpty(token) ? req.headers.set('R-Shop', token).set('Content-Type', 'application/json') : req.headers.set('Content-Type', 'application/json');

  const changedReq = req.clone({headers: headers});

  return next(changedReq).pipe(map((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        loadSvc.setLoading(false, req.url);
      }
      return event;
    }),
    catchError((error) => {
      loadSvc.setLoading(false, req.url);
      throw error;
    }));
}


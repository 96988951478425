import {Component} from '@angular/core';
import {ChildrenOutletContexts, Router, RouterOutlet} from "@angular/router";
import {WebsiteService} from "./services/website.service";
import {FaConfig} from "@fortawesome/angular-fontawesome";
import {CustomIconsService} from "./services/custom-icons.service";
import {LoadingService} from "./services/loading.service";
import {NgProgressComponent} from 'ngx-progressbar';
import {AsyncPipe, NgStyle} from '@angular/common';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {slideInAnimation} from "./animations";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet, NgProgressComponent, NgStyle, MatProgressSpinner, AsyncPipe],

})
export class AppComponent {
  title = 'SwiftServe Orders UI';

  constructor(private websiteSvc: WebsiteService, public router: Router, faConfig: FaConfig, private iconsSvc: CustomIconsService, public loadingSvc: LoadingService) {
    faConfig.defaultPrefix = 'far';
    this.iconsSvc.init();
  }


}

import {animate, animateChild, group, query, style, transition, trigger} from "@angular/animations";

export const slideInAnimation = trigger('routeAnimations', [
  transition('* <=> *', [
    query(':enter, :leave', [
        style({
          position: 'absolute',
          // top: 0,
          maxWidth: '1024px',
          width: '100%',
          height: '100%',
        })
      ], {optional: true}),
    group([
      query(':enter',[
        style({transform: 'scale(0)'}),
        animate('300ms', style({transform: 'scale(1)'}))
      ], {optional: true}),
      query(':leave',[
        style({transform: 'scale(1)'}),
        animate('300ms', style({transform: 'scale(0)'}))
      ], {optional: true})
    ])


    // style({position: 'relative'}),
    // query(':enter, :leave', [
    //   style({
    //     position: 'absolute',
    //     top: 0,
    //     left: 0,
    //     width: '100%'
    //   })
    // ], {optional: true}),
    // query(':enter', [
    //   style({left: '-100%'})
    // ], {optional: true}),
    // query(':leave', animateChild(), {optional: true}),
    // group([
    //   query(':leave', [
    //     animate('200ms ease-out', style({left: '100%', opacity: 0}))
    //   ], {optional: true}),
    //   query(':enter', [
    //     animate('300ms ease-out', style({left: '0%'}))
    //   ], {optional: true}),
    //   query('@*', animateChild(), {optional: true})
    // ]),
  ]),
  // transition('selectorPage => *', [
  //   // query(':enter, :leave',
  //   //   style({position: 'fixed', width: '100%'}),
  //   //   {optional: true}),
  //   group([
  //     query(':enter', [
  //       style({transform: 'translateX(100%)'}),
  //       animate('0.5s ease-in-out',
  //         style({transform: 'translateX(0%)'}))
  //     ], {optional: true}),
  //     query(':leave', [
  //       style({transform: 'translateX(0%)'}),
  //       animate('0.5s ease-in-out',
  //         style({transform: 'translateX(-100%)'}))
  //     ], {optional: true}),
  //   ])
  // ]),
  // transition('shopPage => *', [
  //   query(':enter, :leave',
  //     style({position: 'fixed', width: '100%'}),
  //     {optional: true}),
  //   group([
  //     query(':enter', [
  //       style({transform: 'translateX(-100%)'}),
  //       animate('0.5s ease-in-out',
  //         style({transform: 'translateX(0%)'}))
  //     ], {optional: true}),
  //     query(':leave', [
  //       style({transform: 'translateX(0%)'}),
  //       animate('0.5s ease-in-out'),
  //       style({transform: 'translateX(100%)'})
  //     ], {optional: true}),
  //   ])
  // ]),
]);

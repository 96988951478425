import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogClose, MatDialogContent, MatDialogRef} from "@angular/material/dialog";
import {WebsitePopupModel} from "../../models/WebsitePopupModel";
import {MatIcon} from "@angular/material/icon";
import {MatIconButton} from "@angular/material/button";


@Component({
  selector: 'app-popup-details',
  standalone: true,
  imports: [
    MatDialogClose,
    MatIcon,
    MatIconButton,
    MatDialogContent

  ],
  templateUrl: './popup-details.component.html',
  styleUrl: './popup-details.component.scss'
})
export class PopupDetailsComponent {

  constructor(public dialogRef: MatDialogRef<PopupDetailsComponent>, @Inject(MAT_DIALOG_DATA) public data: WebsitePopupModel){

  }
}

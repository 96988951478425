<div class="px-2 pt-3">
  <div class="d-flex justify-content-start align-items-center flex-grow-1 position-relative">
    <div class="text-center flex-grow-1 d-flex flex-column">
      <span class="mat-headline-6 mb-0">{{ data.name }}</span>
    </div>
    <button mat-dialog-close="false" mat-icon-button class="close-button-absolute">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<mat-dialog-content class="mat-body px-5 py-4 text-center">
  <div [innerHTML]="data.embedCode" class="mt-3"></div>
</mat-dialog-content>

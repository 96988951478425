import {APP_INITIALIZER, enableProdMode, ErrorHandler, inject} from '@angular/core';
import * as Sentry from "@sentry/angular";

import {environment} from './environments/environment';
import {provideHttpClient, withInterceptors} from '@angular/common/http';
import {AuthHttpInterceptorFn} from './app/interceptors/auth-http-interceptor';
import {provideEnvironmentNgxMask} from 'ngx-mask';
import {provideNgxStripe} from 'ngx-stripe';
import {IsActiveMatchOptions, provideRouter, Router, withViewTransitions} from '@angular/router';
import {provideAnimations} from '@angular/platform-browser/animations';
import {AppComponent} from './app/app.component';
import {bootstrapApplication} from '@angular/platform-browser';
import {ROUTES} from "./app/app-routes";


Sentry.init({
  dsn: "https://f52e2c9a55139cbf9d06f4a0cdaa24a7@o4507557136760832.ingest.us.sentry.io/4507557143576576",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: environment.production ? 0 : 0.001, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a
  // lower
  // rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: environment.production ? 'production' : 'development',
});

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(ROUTES, withViewTransitions({
      onViewTransitionCreated: ({transition}) => {
        const router = inject(Router);
        const targetUrl = router.getCurrentNavigation()!.finalUrl!;
        // Skip the transition if the only thing
        // changing is the fragment and queryParams
        const config: IsActiveMatchOptions = {
          paths: 'exact',
          matrixParams: 'exact',
          fragment: 'ignored',
          queryParams: 'ignored',
        };
        if (router.isActive(targetUrl, config)) {
          transition.skipTransition();
        }
      }
    })),
    provideEnvironmentNgxMask({clearIfNotMatch: true}),
    provideNgxStripe(),
    provideHttpClient(withInterceptors([AuthHttpInterceptorFn])),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
    provideAnimations()
  ]
})
  .catch(err => console.error(err));
